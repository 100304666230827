import React from 'react'
import Message from '../../../Components/Message/Message'
import image from '../../../Assets/images/Handicraft/handicraft5.png'
import './index.css';

const HandicraftServices_5 = () => {
  return (
    <div className="HandicraftServices_5">
        <Message 
        gmail={"handicraft.outcircle@gmail.com"}
        admin={"outcircle2023@gmail.com"}
        change_route={"handicraft/service5"}
        user={"user_handicraft"}
        image={image} text={"(Real estate, transportation, financial,  licenses ..etc.) services"}
        head={"Handicraft"} address={"(Real estate, transportation, financial, licenses ..etc.) services"}
        text1={"Follow up the transactions of real estate, financial and municipality"}
        text2={"Follow up the transactions of licenses and social affairs"}
        text3={"Follow up the transactions in transportation directorate and other government department"}
        text4={"Selling or buying a real estate or a car according to the required specifications"}
        text5={"Providing consultations before purchasing real estate and cars, and ensuring that the identification papers are correct and free from legal problems"}
        text6={"...."}
        text7={"...."}
        text8={"...."}
        />
    </div>
  )
}

export default HandicraftServices_5