import React from 'react'
import MessageAr from '../../../Components/Message/Message-Ar'
import image1 from '../../../Assets/images/scientific_ar/image5.png'
const ScientificServiceAr_5 = () => {
return (
    <div>
        <MessageAr 
        gmail={"scientific.professions.outcircle@gmail.com"}
        admin={"outcircle2023@gmail.com"}
        change_route={"scientific/service5-ar"}
        user={"user_scientific"}
        image={image1} text={"خدمات (عقارات ، مواصلات ، مالية ، سجل تجاري .. إلخ)"}
        head={"مهن علمية"} address = {"خدمات (عقارات ، مواصلات ، مالية ، سجل تجاري .. إلخ)"}
        text1={"متابعة معاملات الدوائر العقارية والمالية والبلدية"}
        text2={"متابعة معاملات السجل التجاري والشؤون الاجتماعية"}
        text3={"متابعة المعاملات في مديرية النقل والدوائر الحكومية الأخرى"}
        text4={"بيع أو شراء عقار أو سيارة حسب المواصفات المطلوبة"}
        text5={"تقديم الاستشارات قبل شراء العقارات و السيارات والتأكد من صحة الأوراق الثبوتية وأنها خالية من المشاكل القانونية"}
        text6={"...."}
        text7={"...."}
        text8={"...."}
        />
    </div>
)
}

export default ScientificServiceAr_5;