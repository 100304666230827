import React , {useEffect} from 'react'
import Message from '../../../Components/Message/Message'
import image from '../../../Assets/images/personal_services/Group 445.png';
import {useNavigate} from "react-router-dom";

const PersonalService_7 = () => {

  const navigate = useNavigate();

  useEffect(()=> {
    navigate("/personal-services");
  }, [navigate]);

  return (
    <div>
        <Message 
        gmail={"p.individuals.outcircle@gmail.com"}
        admin={"outcircle2023@gmail.com"}
        change_route={"personal-services/service7"}
        user={"user_individuals"}
        image={image} text={"Alternative energy services"}
        head={"individuals"} address={"Alternative energy services"}
        text1={"Provide honest advice about suitable alternative energy products and assess the real needs of each person"}
        text2={"Assistance in providing guaranteed alternative energy systems at reasonable and competitive prices."}
        text3={"Assist in updating or replacing existing systems at a reasonable and appropriate cost."}
        text4={"...."}
        text5={"...."}
        text6={"...."}
        text7={"...."}
        text8={"...."}
        />
    </div>
  )
}

export default PersonalService_7;