import React , {useEffect} from 'react'
import Message from '../../../Components/Message/Message'
import image from '../../../Assets/images/personal_services/Group 470.png';
import {useNavigate} from "react-router-dom";

const PersonalService_3 = () => {

  const navigate = useNavigate();

  useEffect(()=> {
    navigate("/personal-services");
  }, [navigate]);
  

  return (
    <div>
        <Message 
        gmail={"p.individuals.outcircle@gmail.com"}
        admin={"outcircle2023@gmail.com"}
        change_route={"personal-services/service3"}
        user={"user_individuals"}
        image={image} text={"Gift and occasions services"}
        head={"individuals"} address={"Gift and occasions services"}
        text1={"Presenting completely new ideas in the type of desired gift."}
        text2={"Follow on the coordination and presentation of gifts"}
        text3={"Providing ideas for coordination and organization of occasions appropriately"}
        text4={"Management and follow-up coordination of occasions"}
        text5={"...."}
        text6={"...."}
        text7={"...."}
        text8={"...."}
        />
    </div>
  )
}

export default PersonalService_3