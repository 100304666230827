import React from 'react'
import Message from '../../../Components/Message/Message';
import image from '../../../Assets/images/Corporate/corporate5.png';
import './index.css';


const CorporateService_5 = () => {
  return (
    <div className="CorporateService_5">
        <Message 
        admin={"outcircle2023@gmail.com"}
        gmail={"corporate.outcircle@gmail.com"}
        change_route={"corporate/service5"}
        user={"user_corporate"}
        image={image} text={"(Real estate, transportation, financial, commercial registry ..etc.) services"}
        head={"Corporate"} address={"(Real estate, transportation, financial, commercial registry ..etc.) services"}
        text1={"Follow up the transactions of real estate, financial and municipality"}
        text2={"Follow up the transactions of commercial registry and social affairs"}
        text3={"Follow up the transactions in transportation directorate and other government department"}
        text4={"Selling or buying a real estate or a car according to the required specifications"}
        text5={"Providing consultations before purchasing real estate and cars, and ensuring that the identification papers are correct and free from legal problems"}
        text6={"...."}
        text7={"...."}
        text8={"...."}
        />
    </div>
  )
}

export default CorporateService_5;