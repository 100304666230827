import React , {useEffect}  from 'react';
import Message from '../../../Components/Message/Message';
import image from '../../../Assets/images/personal_services/Group 472.png';
import {useNavigate} from "react-router-dom";

const PersonalService_2 = () => {
  const navigate = useNavigate();

  useEffect(()=> {
    navigate("/personal-services");
  }, [navigate]);
  
  return (
    <div>
        <Message 
        gmail={"p.individuals.outcircle@gmail.com"}
        admin={"outcircle2023@gmail.com"}
        change_route={"personal-services/service2"}
        user={"user_individuals"}
        image={image} text={"Consultations on general issues and problems"}
        head={"individuals"} address={"Consultations on general issues and problems"}
        text1={"Providing consultations, advice and solutions in any general issue or problem."}
        text2={"Sharing ideas and providing the best solution"}
        text3={"Providing advice on personal issues if desired (as a humanitarian service)."}
        text4={"Providing practical assistance if possible"}
        text5={"...."}
        text6={"...."}
        text7={"...."}
        text8={"...."}
        />
    </div>
  )
}

export default PersonalService_2