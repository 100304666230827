import React from 'react'
import Message from '../../../Components/Message/Message'
import image from '../../../Assets/images/Career_services/Group 463.png'
const CareerService_2 = () => {
  return (
    <div>
        <Message 
        gmail={"c.individuals.outcircle@gmail.com"}
        admin={"outcircle2023@gmail.com"}
        change_route={"career-services/service2"}
        user={"user_individuals"}
        image={image} text={"Providing a job opportunity"}
        head={"individuals"} address={"Providing a job opportunity"}
        text1={"Providing a job opportunity in the field of your scientific or practical experience with real impartiality according to your entitlement"}
        text2={"Providing the highest possible income for you by employing you in several fields together"}
        text3={"The possibility to get a job opportunity with us according to your skills"}
        text4={"Providing a business opportunity in your home (marketing / non-marketing)"}
        text5={"...."}
        text6={"...."}
        text7={"...."}
        text8={"...."}
        />
    </div>
  )
}

export default CareerService_2
